import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import image from '../image/logoFinal.png';

const navigation = [
    {
        name: 'Inicio', href: '#Home', current: true,
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 9.5L12 3l9 6.5V21a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5z" />
            <path d="M9 21V12h6v9" />
        </svg>
    },
    {
        name: 'Nosotros', href: '#About', current: false,
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="m7.5 4.27 9 5.15" />
            <path
                d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
        </svg>
    },
    {
        name: 'Servicios', href: '#Services', current: false,
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path
                d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        </svg>
    },
    {
        name: 'Clientes', href: '#Clients', current: false,
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <circle cx="12" cy="12" r="10" />
            <path d="M12 16v-4" />
            <path d="M12 8h.01" />
        </svg>
    },
    {
        name: 'Contacto', href: '#Contact', current: false,
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <circle cx="12" cy="12" r="10" />
            <path d="M12 16v-4" />
            <path d="M12 8h.01" />
        </svg>
    },
]

export default function Navbar() {
    return (
        <Disclosure as="nav" className="bg-white/90 backdrop-blur-md">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 right-1 flex items-center sm:hidden">

                        {/* Mobile menu button*/}
                        <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            <button className="flex h-6 w-6 group-data-[open]:hidden">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 100 80"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="100" height="10" />
                                    <rect y="30" width="100" height="10" />
                                    <rect y="60" width="100" height="10" />
                                </svg>
                            </button>

                            <button className="hidden h-6 w-6 group-data-[open]:flex">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 100 100"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <line x1="10" y1="10" x2="90" y2="90" stroke="currentColor" strokeWidth="10" />
                                    <line x1="90" y1="10" x2="10" y2="90" stroke="currentColor" strokeWidth="10" />
                                </svg>
                            </button>
                        </DisclosureButton>
                    </div>

                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        {/* Logo */}
                        <div className="flex flex-shrink-0 items-center">
                            <a
                                href='#Home'
                            >
                                <img
                                    alt="Your Company"
                                    src={image}
                                    className="h-10 w-auto rounded-md"
                                />
                            </a>

                        </div>


                    </div>

                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        {/* Menu */}
                        <div className="hidden sm:ml-6 sm:block">
                            <div className="flex space-x-4">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className={'text-sm font-medium text-gray-700 hover:text-primary'}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DisclosurePanel className="sm:hidden">
                <div className="flex flex-col justify-end items-end space-y-1 px-2 pb-3 pt-2">
                    {navigation.map((item) => (
                        <DisclosureButton
                            key={item.name}
                            className="flex min-w-40 items-center gap-3 rounded-md  text-sm font-medium transition-colors hover:bg-gray-200 hover:text-primary"
                        >
                            <a href={item.href}
                                className='flex w-full p-2  gap-2'>
                                {item.icon}
                                {item.name}
                            </a>
                        </DisclosureButton>
                    ))}
                </div>
            </DisclosurePanel>
        </Disclosure>
    )
}

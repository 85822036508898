import { useState, useCallback, useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import imgAlinea from '../image/Alineación.jpeg';
import imgAnali from '../image/Análisis_vibraciones.jpeg';
import imgAuto from '../image/Automatización.jpeg';
import imgComisio from '../image/Comisionado.jpeg';
import imgInst from '../image/Instrumentación.jpeg';
import imgMant from '../image/Mantenimiento.jpeg';
import imgVenta from '../image/Insumos.jpeg';

const services = [
    {
        id: 1, title: "Alineación de ejes y poleas",
        image: imgAlinea,
        description: "Utilizan equipos de última generación para corregir desalineaciones angulares y paralelas, asegurando que los ejes y poleas estén perfectamente alineados para reducir vibraciones y el desgaste de componentes."
    },
    {
        id: 2, title: "Análisis de vibraciones",
        image: imgAnali,
        description: "Ofrece servicios de análisis de vibraciones para el mantenimiento predictivo de equipos industriales. Este servicio permite detectar problemas ocultos en maquinaria antes de que se conviertan en fallos mayores, optimizando el rendimiento y reduciendo los costos de reparación."
    },
    {
        id: 3, title: "Automatización industrial",
        image: imgAuto,
        description: "Ofrece servicios de automatización industrial diseñados para optimizar y modernizar los procesos productivos de sus clientes. La empresa se especializa en la implementación de soluciones tecnológicas que integran sistemas de control, monitoreo y supervisión de maquinaria, mejorando la eficiencia y reduciendo los costos operativos."
    },
    {
        id: 4, title: "Comisionado y puesta en marcha de equipos rotativos",
        image: imgComisio,
        description: "Proporciona servicios de comisionado y puesta en marcha de equipos rotativos, asegurando que cada equipo funcione de manera óptima desde el inicio de sus operaciones. Estos servicios están diseñados para verificar la instalación correcta, realizar ajustes finales y asegurar que los equipos cumplan con los estándares de rendimiento especificados por el fabricante y las necesidades del cliente."
    },
    {
        id: 5, title: "Instrumentación y control de procesos",
        image: imgInst,
        description: "Ofrece servicios especializados en instrumentación y control de procesos, enfocados en optimizar la eficiencia y precisión de los sistemas industriales. La empresa se encarga del diseño, instalación, calibración y mantenimiento de instrumentos que monitorean y controlan variables críticas como temperatura, presión, caudal y nivel, asegurando que los procesos industriales operen de manera segura y eficiente."
    },
    {
        id: 6, title: "Mantenimiento de equipos rotativos",
        image: imgMant,
        description: "Brinda servicios de mantenimiento de equipos rotativos, asegurando el funcionamiento óptimo y prolongando la vida útil de maquinaria crítica como bombas, compresores, ventiladores y motores. La empresa ofrece un enfoque integral que abarca mantenimiento preventivo, correctivo y predictivo, ajustándose a las necesidades específicas de cada cliente."
    },
    {
        id: 7, title: "Ventas de insumos y repuestos",
        image: imgVenta,
        description: "Ofrece un servicio integral de venta de insumos y repuestos para equipos industriales, proporcionando a sus clientes piezas de alta calidad y productos esenciales para el mantenimiento y funcionamiento eficiente de sus sistemas. La empresa cuenta con un amplio catálogo de componentes que incluye rodamientos, correas, sellos mecánicos, acoplamientos, sensores y otros repuestos críticos para equipos rotativos y sistemas de automatización."
    },
]

export default function Services() {
    const [expandedService, setExpandedService] = useState(null)
    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: 'start',
        slidesToScroll: 1,
        breakpoints: {
            '(min-width: 640px)': { slidesToScroll: 2 },
            '(min-width: 1024px)': { slidesToScroll: 3 },
        },
    })

    const [canScrollPrev, setCanScrollPrev] = useState(false)
    const [canScrollNext, setCanScrollNext] = useState(true)

    const onSelect = useCallback(() => {
        if (!emblaApi) return
        setCanScrollPrev(emblaApi.canScrollPrev())
        setCanScrollNext(emblaApi.canScrollNext())
    }, [emblaApi])

    useEffect(() => {
        if (!emblaApi) return
        onSelect()
        emblaApi.on('select', onSelect)
        emblaApi.on('reInit', onSelect)
        return () => {
            emblaApi.off('select', onSelect)
            emblaApi.off('reInit', onSelect)
        }
    }, [emblaApi, onSelect])

    const toggleDescription = (id) => {
        setExpandedService(expandedService === id ? null : id)
    }

    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])

    return (
        <section id="Services" className="flex items-center w-full min-h-[750px] ">
            <div className="mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">Nuestros Servicios</h2>

                <div className="relative max-w-7xl mx-auto">
                    <div className="overflow-hidden" ref={emblaRef}>
                        <div className="flex rounded-md">
                            {services.map((service) => (
                                <div key={service.id} className="flex-[0_0_100%] rounded-md min-w-0 sm:flex-[0_0_50%] lg:flex-[0_0_33.333%] px-4">
                                    <div className="h-full">
                                        <div className="p-0 relative rounded-md">
                                            <img
                                                src={service.image}
                                                alt={service.title}
                                                className="w-full h-[400px] object-cover blur-sm rounded-md"
                                            />
                                            <div className="absolute rounded-md inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center p-4">
                                                <h3 className="text-white text-xl font-semibold text-center mb-2">

                                                    <span className="bg-[orange] bg-opacity-75 px-2 py-1 rounded-md">
                                                        {service.title}
                                                    </span>
                                                </h3>
                                                {expandedService === service.id && (
                                                    <div className="p-4">
                                                        <span className="text-black bg-white/60 px-2 rounded">
                                                            {service.description}
                                                        </span>
                                                    </div>
                                                )}
                                                <button
                                                    onClick={() => toggleDescription(service.id)}
                                                    variant="secondary"
                                                    size="sm"
                                                    className="border-solid border rounded-md bg-white/50 p-1 flex"
                                                >
                                                    {expandedService === service.id ? <svg width="15" height="15" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20 50 L80 50" stroke="black" stroke-width="10" fill="none" />
                                                    </svg> : <svg width="15" height="15" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M50 20 L50 80 M20 50 L80 50" stroke="black" stroke-width="10" fill="none" />
                                                    </svg>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button
                        className="absolute left-0 top-1/2 border-solid border rounded-md bg-white/50 p-2 z-10 hidden sm:flex"
                        onClick={scrollPrev}
                        disabled={!canScrollPrev}
                    >
                        <svg width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M75 20 L25 50 L75 80" stroke="black" stroke-width="10" fill="none" />
                        </svg>
                        <span className="sr-only">Anterior</span>
                    </button>
                    <button
                        variant="outline"
                        size="icon"
                        className="absolute right-0 top-1/2 border-solid border rounded-md bg-white/50 p-2 z-10 hidden sm:flex"
                        onClick={scrollNext}
                        disabled={!canScrollNext}
                    >
                        <svg width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 20 L75 50 L25 80" stroke="black" stroke-width="10" fill="none" />
                        </svg>
                        <span className="sr-only">Siguiente</span>
                    </button>
                </div>
                <div className="flex justify-center mt-4 sm:hidden">
                    <button
                        variant="outline"
                        size="sm"
                        onClick={scrollPrev}
                        disabled={!canScrollPrev}
                        className="border-solid border rounded-md m-2 px-2 py-1 bg-white/50 flex cursor-pointer"
                    >
                        <svg width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M75 20 L25 50 L75 80" stroke="black" stroke-width="10" fill="none" />
                        </svg>
                    </button>
                    <button
                        variant="outline"
                        size="sm"
                        onClick={scrollNext}
                        disabled={!canScrollNext}
                        className="border-solid border rounded-md m-2 px-2 py-1 bg-white/50 flex cursor-pointer"
                    >
                        <svg width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 20 L75 50 L25 80" stroke="black" stroke-width="10" fill="none" />
                        </svg>
                    </button>
                </div>
            </div>
        </section>
    )
}
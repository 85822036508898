import { useEffect, useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import imgEnergy from "../image/ENERGYPETROL_1.jpeg";
import imgEcua from "../image/ECUAMBIENTAL_1.jpeg";
import imgPes from "../image/PES_1.jpeg";
import imgGafca from "../image/GAFCA_1.jpeg";

const clients = [
    { name: "Cliente 1", logo: imgEnergy },
    { name: "Cliente 2", logo: imgEcua },
    { name: "Cliente 3", logo: imgPes },
    { name: "Cliente 4", logo: imgGafca },
];

export default function Clients() {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        align: "start",
        slidesToScroll: 1, // Se mueve de uno en uno
        skipSnaps: false,
    });
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setCurrent(emblaApi.selectedScrollSnap());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        setCount(emblaApi.scrollSnapList().length);
        emblaApi.on("select", onSelect);
    }, [emblaApi, onSelect]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (emblaApi) {
                emblaApi.scrollNext();
            }
        }, 5000); // Cambia cada 5 segundos

        return () => clearInterval(timer);
    }, [emblaApi]);

    return (
        <section
            id="Clients"
            className="flex items-center justify-center w-full min-h-[650px] py-12 bg-[orange]"
        >
            <div className="container px-4 md:px-6 max-w-4xl">
                <h2 className="text-3xl font-bold text-center mb-8">
                    Nuestros Clientes
                </h2>
                <div className="embla overflow-hidden" ref={emblaRef}>
                    {/* Oculta el desbordamiento */}
                    <div className="embla__container flex">
                        {clients.map((client, index) => (
                            <div
                                key={index}
                                className="embla__slide flex-[0_0_50%]" // Cada slide ocupa el 50%
                            >
                                <div className="flex items-center justify-center p-6">
                                    <div className="flex justify-center p-5 bg-white w-full h-full rounded-md">
                                        <img
                                            src={client.logo}
                                            alt={`Logo de ${client.name}`}
                                            className="max-w-full h-auto"
                                            width={200}
                                            height={100}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

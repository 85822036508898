import imgCalidad from '../image/Calidad.png';
import imgInnova from '../image/Innovación.png';
import imgCompro from '../image/Compromiso.png';
import imgCola from '../image/Colaboración.png';
import imgAdap from '../image/Adaptación.png';
import imgPro from '../image/Profesionalismo.png';

export function Valors() {
    return (
        (<section className="bg-[#f3f4f6] py-12 md:py-16 lg:py-20 px-4 md:px-6 flex min-h-screen items-center justify-center">
            <div className="container">
                <div className="mb-8 text-center">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Valores</h2>
                    <p>IMSELEC se distingue por sus valores fundamentales:          </p>
                </div>
                <div
                    className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    <div className="flex flex-col items-center">
                        <img
                            src={imgCalidad}
                            width={80}
                            height={80}
                            alt="Integrity"
                            className="mb-4"
                            style={{ aspectRatio: "80/80", objectFit: "cover" }}
                        />
                        <h3 className="mb-2 text-lg font-semibold">Calidad</h3>
                        <p className="text-muted-foreground">
                            Compromiso con productos y servicios de alto estándar.
                        </p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={imgInnova}
                            width={80}
                            height={80}
                            alt="Innovation"
                            className="mb-4"
                            style={{ aspectRatio: "80/80", objectFit: "cover" }}
                        />
                        <h3 className="mb-2 text-lg font-semibold">Innovación</h3>
                        <p className="text-muted-foreground">Constante búsqueda de soluciones creativas y tecnológicas.</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={imgCompro}
                            width={80}
                            height={80}
                            alt="Collaboration"
                            className="mb-4"
                            style={{ aspectRatio: "80/80", objectFit: "cover" }}
                        />
                        <h3 className="mb-2 text-lg font-semibold">Compromiso</h3>
                        <p className="text-muted-foreground">Responsabilidad y dedicación hacia clientes y colaboradores</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={imgCola}
                            width={80}
                            height={80}
                            alt="Excellence"
                            className="mb-4"
                        />
                        <h3 className="mb-2 text-lg font-semibold">Colaboración</h3>
                        <p className="text-muted-foreground">Trabajo en equipo y relaciones sólidas con clientes y socios.</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={imgAdap}
                            width={80}
                            height={80}
                            alt="Accountability"
                            className="mb-4"
                        />
                        <h3 className="mb-2 text-lg font-semibold">Adaptación</h3>
                        <p className="text-muted-foreground">Flexibilidad para ajustarse a cambios y necesidades del mercado.</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={imgPro}
                            width={80}
                            height={80}
                            alt="Customer Focus"
                            className="mb-4"
                        />
                        <h3 className="mb-2 text-lg font-semibold">Profesionalismo</h3>
                        <p className="text-muted-foreground">
                            Ética y excelencia en todas las interacciones y operaciones.
                        </p>
                    </div>
                </div>
            </div>
        </section>)
    );
}
import './App.css';
import { About } from './layouts/About';
import Clients from './layouts/Clients';
import Contact from './layouts/Contact';
import Home from './layouts/Home';
import Navbar from './layouts/Navbar';
import Services from './layouts/Services';
import { Valors } from './layouts/Valors';
import image from './image/logoFinal.png';

function App() {
  return (
    <div className="App">
      <header className='sticky top-0 z-50'>
        <Navbar />
      </header>
      <Home />
      <About />
      <Valors />
      <Services />
      <Clients />
      <Contact />
      <footer className="bg-[#f3f4f6] py-6 text-background">
        <div
          className="container mx-auto flex flex-col items-center justify-between gap-4 px-4 md:flex-row">
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt="About Chill Tech"
              className="rounded-lg w-auto h-10"
            />
          </div>
          <div className='flex items-center flex-col md:flex-row gap-3'>
            <p className="text-sm">© 2024 Flor Segovia.</p>
            <p>Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;

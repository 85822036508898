import React, { useEffect, useRef } from 'react'
import leaflet from 'leaflet';

const Map = ({ lat, lng }) => {
    const mapRef = useRef(null);

    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = leaflet.map("map").setView([-0.243145, -78.505690], 19);

            leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(mapRef.current);

            leaflet.marker([-0.243145, -78.505690]).addTo(mapRef.current)
                .bindPopup('Oficinas<br> IMSELEC')
                .openPopup();
        }
    }, []);

    return <div id="map" style={{ height: "100%", width: "100%" }}></div>;
}

export default Map
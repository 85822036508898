import image from '../image/engranaje.png';
import imgWhatsApp from '../image/whatsapp.png';
import Map from './Map';

export default function Contact() {
    return (
        <div id="Contact" className="w-full px-4 md:px-6 lg:px-0">
            <br />
            <h2 className="text-3xl font-bold text-center mb-8">Contáctanos</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 p-8">
                <div className=" bg-cover bg-center p-2 px-10 bg-gray-100 rounded-md" style={{ backgroundImage: `url(${image})` }}>
                    <div className="my-6">
                        <h3 className="text-xl text-center font-semibold mb-2">Información de Contacto</h3>
                        <div className="space-y-1 text-left">
                            <p>Email: info@empresa.com</p>
                            <p>Dirección: Juan Vizuete & Ramon Pacheco, Quito, Ecuador</p>
                            <p>Horario: Lunes a Domingo, 8:00 AM - 5:00 PM</p>
                        </div>
                    </div>
                    <br />
                    <h3 className="text-xl text-center font-semibold mb-2">Técnicos</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <div className="space-y-1 text-left">
                                <p>Ing. Bolivar Suarez</p>
                                <p>Gerente Técnico</p>
                                <p className="inline-flex gap-5">
                                    <img
                                        src={imgWhatsApp}
                                        width={20}
                                        height={20}
                                        alt="Innovation"
                                        className="rounded-lg object-cover"
                                        style={{ aspectRatio: "80/80", objectFit: "cover" }}
                                    />
                                    <a href="https://wa.me/message/YM76LDKIQYXUC1" prefetch={false}>
                                        095 863 8670
                                    </a>
                                </p>
                                <br />
                                <p className="inline-flex gap-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <rect width="20" height="16" x="2" y="4" rx="2" />
                                        <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                                    </svg>
                                    <a href="mailto:bsuarez@imselect.com">bsuarez@imselec.com</a>
                                </p>
                                <br />

                            </div>
                        </div>
                        <div>
                            <div className="space-y-1 text-left">
                                <p>Ing. Dario Zapata</p>
                                <p>Ingeniero Especialista</p>
                                <p className="inline-flex">
                                    <img
                                        src={imgWhatsApp}
                                        width={20}
                                        height={20}
                                        alt="Innovation"
                                        className="rounded-lg object-cover"
                                        style={{ aspectRatio: "80/80", objectFit: "cover" }}
                                    />
                                    <a href="https://wa.me/message/AVDL4G5OHBBIM1" prefetch={false} className="ml-2">
                                        099 800 6792
                                    </a>
                                </p>
                                <br />
                                <p className="inline-flex gap-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <rect width="20" height="16" x="2" y="4" rx="2" />
                                        <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                                    </svg>
                                    <a href="mailto:dzapta@imselect.com">dzapta@imselec.com</a>
                                </p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="h-[400px] rounded-md overflow-hidden">
                        <Map lat={-0.243145} lng={-78.505690} />
                    </div>
                </div>
            </div>
        </div>
    );
}
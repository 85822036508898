export function About() {
    return (
        <section id="About" className="flex items-center py-12 min-h-[600px]">
            <div className="max-w-7xl mx-auto px-2 sm:py-24 lg:py-32">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Sobre nosotros */}
                    <div className="p-6 bg-[#f3f4f6] rounded-lg shadow-md border-solid border">
                        <h2 className="text-2xl text-left font-bold mb-4">Sobre nosotros</h2>
                        <p className="text-gray-600 text-left">
                            IMSELEC es un líder en soluciones electromecánicas y venta de insumos industriales. Nos destacamos por nuestra dedicación a la eficiencia y la innovación, ofreciendo productos y servicios de alta calidad que cumplen con las demandas más exigentes.
                        </p>
                        <p className="text-gray-600 text-left mt-2">
                            Ofrecemos desde sistemas eléctricos y mecánicos personalizados hasta componentes electrónicos y soluciones de automatización, siempre con un compromiso firme hacia la excelencia y la asistencia técnica oportuna.
                        </p>
                    </div>

                    {/* Visión */}
                    <div className="p-6 bg-[#f3f4f6] rounded-lg shadow-md border-solid borde">
                        <h2 className="text-2xl text-left font-bold mb-4">Visión</h2>
                        <p className="text-gray-600 text-left">
                            La empresa busca ser la opción preferida por los clientes al ofrecer productos y servicios que mejoren la eficiencia y competitividad de las operaciones industriales, estableciendo estándares de excelencia y contribuyendo al avance tecnológico en el sector industrial.
                        </p>
                    </div>

                    {/* Misión */}
                    <div className="p-6 bg-[#f3f4f6] rounded-lg shadow-md border-solid borde">
                        <h2 className="text-2xl text-left font-bold mb-4">Misión</h2>
                        <p className="text-gray-600 text-left">
                            IMSELECT ofrece productos y servicios de alta calidad que optimicen el rendimiento de los sistemas electromecánicos en diversas industrias.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
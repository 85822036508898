import image from '../image/pixelcut-export.png';

export default function Home() {
    return (
        <section id="Home"
            className="relative w-full h-[600px] md:h-[700px] bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${image})` }}
        >
            <div className="absolute inset-0 bg-black/10" />
            <div className="container relative z-10 py-24 md:py-32 lg:py-40 px-4 md:px-6">
                <div className=" max-w-xl space-y-4">
                    <h2 className="text-3xl text-left font-bold text-[white] sm:text-4xl md:text-5xl">Bienvenidos</h2>
                    <p className="text-lg text-left text-[white] md:text-xl">
                        Descubra nuestras soluciones electromecánicas de vanguardia que están transformando industrias en todo el mundo.
                    </p>
                    <div className='flex'>
                        <a
                            href="#About"
                            className="text-white inline-flex h-10 items-center justify-center rounded-md bg-[orange] px-6 py-2 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-[orange]/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                            prefetch={false}>
                            Más información
                        </a>
                    </div>
                </div>
            </div>

        </section>
    );
}